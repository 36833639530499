body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-flow-node .ant-card-head {
  min-height: 20px;
  padding: 0 8px 0 8px;
}

.react-flow-node .ant-card-actions {
  min-height: 20px;
  padding: 0 8px 0 8px;
}

.react-flow-node .ant-btn {
  padding: 0;
}

.react-flow-node .ant-card-body {
  padding: 16px;
}

.ant-card-body::after {
  display: none;
}

.pagination-bar {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* disable interactions with elements beneath the modal */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  min-width: 300px;
  pointer-events: auto; /* re-enable interactions for the modal content */
}

.loading-phrases {
  margin-top: 12px;
}

.site-layout-background {
  border-right: 0.2px solid #e8e8e8;
  padding: 24px;
  min-height: 280px;
}

.spin-container-box {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.hoverContainer {
  opacity: 0;
  transition: opacity 0.1s ease;
}

.hoverParent:hover .hoverContainer {
  opacity: 1;
}

.ant-list-item {
  border-bottom: none !important;
  padding: 2px !important;
}

.icons_icon {
  fill: currentcolor;
}

.icons_icon--md {
  height: 16px;
  width: 16px;
}

.nested-table .ant-table-thead > tr > th {
  background: #ffffff;
}

.ant-table-expanded-row .ant-table-cell {
  background: #ffffff !important;
}

.inherited-styles-for-exported-element {
  box-sizing: border-box;
  color: rgba(28, 43, 52, 0.98);
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}

.form_button {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-image: none 100% 1 0 stretch;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(28, 43, 52, 0.68);
  cursor: pointer;
  display: inline-flex;
  height: 28px;
  line-height: 26px;
  flex-shrink: 0;
  font-family: NotoSans, "Lucida Grande", "Lucida Sans Unicode", sans-serif;
  font-weight: 400;
  justify-content: center;
  margin: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-wrap: nowrap;
  white-space-collapse: collapse;
}

.form_button__icon-wrapper {
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  height: 100%;
  vertical-align: top;
}

.form_input-text__wrapper:focus-within {
  border-color: #006bc2;
  border: 1px solid #006bc2 !important;
  box-shadow: 0 0 3px rgba(0, 107, 194, 0.3);
  background-color: #eaf6fc;
}

.form_input-text__wrapper:hover {
  border: 1px solid #585f70;
}

.groupby-dropdown {
  justify-content: center;
  align-items: center;
  width: 176px !important;
}

.groupby-dropdown-text-icon {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.groupby-dropdown > * {
  margin: 0 5px;
}

.eol-list-item-bordered {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.eol-list-item-bordered:hover {
  transition: background-color 0.6s ease;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.form_input-text__wrapper {
  align-items: center;
  background-color: #fff;
  border: 1px solid #c2c8dd;
  border-image: none 100% 1 0 stretch;
  border-radius: 4px;
  display: inline-flex;
  font-family: NotoSans, "Lucida Grande", "Lucida Sans Unicode", sans-serif;
  line-height: 1.45;
  width: 200px;
}

.form_input-text__wrapper__after-content,
.form_input-text__wrapper__before-content {
  display: flex;
  justify-content: center;
}

.form_input-text__input {
  appearance: none;
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  font-family: inherit;
  line-height: 1.45;
  margin: 0;
  outline: 0;
  width: 100%;
}

.form_input-search__search-icon {
  color: rgba(28, 43, 52, 0.5);
}

.form_input-text__input::placeholder {
  color: rgba(28, 43, 52, 0.5) !important;
  opacity: 1;
}
.light-blue-link {
  color: #6699cc !important;
}

.form_input-text__wrapper {
  height: 28px;
}

.form_input-text__wrapper:hover {
  border-color: #585f70;
}

.form_input-text__input {
  text-indent: 0;
}

.form_input-text__wrapper .form_input-text__input {
  height: 28px;
}

.margin--left-sm {
  margin-left: 8px !important;
}

.margin--right-sm {
  margin-right: 8px !important;
}

.form_button--md {
  font-size: 13px !important;
}

.form_input-text__input {
  background-attachment: initial !important;
  background-clip: initial !important;
  background-color: initial !important;
  background-image: none !important;
  background-origin: initial !important;
  background-position: initial initial !important;
  background-repeat: initial !important;
  background-size: initial !important;
  border: initial !important;
  border-color: initial !important;
  border-image: initial initial initial initial initial !important;
  box-shadow: none !important;
  color: rgba(28, 43, 52, 0.98) !important;
  padding: 0 !important;
}

.form_input-text__wrapper .form_input-text__input {
  font-size: 13px !important;
}

.fixed-height-row {
  height: 4rem !important;
}
